.App {
  text-align: center;
  background-image: url("./table.jpg");
  width: 100vw;
  height: 100vh;
}

.at {
  padding: 0;
  margin: 5px;
}

.boardImg {
  width: 100%;
  height: 100%;
  -webkit-user-drag: none;
  -user-drag: none;
}

.chessBoard {
  position: fixed;
  z-index: 0;
  -webkit-box-shadow: 1px 1px 0px 19px #332a00;
  box-shadow: 1px 1px 0px 19px #332a00;
  user-select: none;
  -webkit-user-drag: none;
}

.piece {
  position: fixed;
}

.piece img {
  user-select: none;
  -webkit-user-drag: none;
  -k-html-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  /* user-drag: none; */
}

.resetButton {
  position: absolute;
  top: 103%;
  padding: 10px 20px;
  margin: 5px 0px 5px 5px;
  right: -20px;
  color: #f5de9d;
  font-weight: bolder;
  text-transform: uppercase;
  background-color: #73582f;
}

.chessBoard p {
  left: -40px;
  padding: 10px 20px;
  margin: 3px;
  color: #060606;
  font-size: 40px;
}

.App__board {
  display: flex;
  background-color: #73582f;
}

.ff {
  background-color: #332a00;
  width: 100vw;
  height: 100vh;
}

.no-play {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: #060606;
  z-index: 100000;
  display: none;
}

.no-play h6 {
  color: grey;
}

@media only screen and (max-width: 740px) {
  .no-play {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    text-align: center;
    flex-direction: column;
  }
}
