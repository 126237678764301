.Home {
  width: 100vw;
  height: 100vh;
  background-image: url("../home.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.Home__header {
  color: white;
  font-family: "Permanent Marker", cursive;
}

.Home__header h1 {
  font-size: 10rem;
  -webkit-text-stroke: 3px rgb(5, 4, 59);
}

.Home__header p {
  font-size: 4rem;
  -webkit-text-stroke: 1px rgb(5, 4, 59);
}

.Home__header button {
  padding: 1rem 3rem;
  font-size: 2rem;
  background-color: rgb(255, 166, 0);
}
